import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import styled from 'styled-components';
import FormDevis from '../components/FormDevis';
import Testimonials from '../components/Testimonials';
import Zones from '../components/Zones';
import Prestations from '../components/Prestations';

import MainCta from '../components/MainCta';

import FaqSection from '../components/FaqSection';
import Breadcrumb from '../components/Breadcrumb';
import BlogSection from '../components/BlogSection';
import Layout from '../components/Layout';

const pages = [
  { name: 'Canton de vaud', href: '/vaud', current: false },
  { name: 'Montreux', href: '/serrurier-montreux/', current: true },
];

export const query = graphql`
  query Montreux_images {
    teamImage: file(relativePath: { eq: "serrurier-team.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bikeImage: file(relativePath: { eq: "bike.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    deplacementGratuitImage: file(
      relativePath: { eq: "deplacement-gratuit.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    genevaMap: file(relativePath: { eq: "geneva-map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    googleImage: file(relativePath: { eq: "google-image.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
const GenevaFaq = [
  {
    id: 1,
    question:
      'Ils ont fait sauter la serrure de la porte d’entrée, que dois-je faire ?',
    answer:
      'Le premier réflexe à avoir est de prévenir la police locale de Montreux. Par la suite, faites appel à Super Serrurier Montreux afin de vous venir en aide rapidement.',
  },
  {
    id: 2,
    question: "Quel est le prix d'un dépannage d'urgence sur Montreux ?",
    answer:
      "Les prix varient en fonction de sa durée, des moyens mis en œuvre ainsi que de la complexité du problème. Mais ayez la certitude qu'avec Super Serrurier Montreux, vous ne risquez pas de vous ruiner ou de subir des surcoûts injustifiés !",
  },
  {
    id: 3,
    question:
      'Comment bien faire son choix de porte blindée pour votre domicile à Montreux ?',
    answer:
      'On trouve sur le marché différents types de portes blindées. Plus ces dernières sont en mesure de protéger votre domicile contre les tentatives de cambriolage, plus leur prix augmente. Faites vous accompagner par nos équipes dans cette démarche, mais aussi pour son installation.',
  },
];

export default function Geneve({ data }) {
  const {
    bikeImage,
    deplacementGratuitImage,
    teamImage,
    genevaMap,
    googleImage,
  } = data;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Serrurier Montreux, meilleurs prix et devis instantané - dépannage
          24/7
        </title>
        <meta
          name="description"
          content="Service remarquable et prix imbattable. Super Serrurier est le mieux noté sur Googles avec 4.9 étoiles et plus de 230 commentaires"
        />
      </Helmet>
      <div className="relative bg-gray-100 overflow-hidden">
        <MainTownHeader
          className="relative  bg-gray-800 pt-6 pb-16 sm:pb-24 py-16"
          id="main-jumbo"
        >
          <main className="mt-24 mx-auto max-w-7xl px-4 sm:mt-10">
            <div className="text-center">
              <div className="flex justify-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-primarycolor sm:text-5xl md:text-6xl ml-4 sm:text-white">
                  Serrurier Montreux
                </h1>
              </div>

              {/* 
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Serrurier</span>
              <span className="block text-white xl:inline">Vaud</span>
            </h1>
              */}
              <h2 className="text-lg lg:text-2xl xl:text-3xl md:block mt-3 text-gray-700 sm:text-white">
                Rapidité, efficacité et transparence des prix
              </h2>
              <p className="text-sm lg:text-lg text-gray-700 sm:text-white mt-4">
                Chez vous en 20 minutes chrono !
              </p>
              {/* 
            <p className="max-w-md mx-auto text-base text-white sm:text-lg md:text-xl md:max-w-3xl">
              Profitez d’un service de qualité et sans mauvaises surprises
            </p>
            */}
            </div>

            <div className="flex justify-center">
              <a
                href="tel:0791304768"
                className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
              >
                <span className="px-3 py-2 text-white text-lg lg:text-xl xl:text-1xl leading-5 tracking-wide bg-primarycolor rounded-full">
                  Appelez-nous
                </span>
                <span className="ml-3 text-lg lg:text-xl xl:text-1xl font-semibold leading-5">
                  079 130 47 68
                </span>
                {/* <!-- Heroicon name: chevron-right --> */}
                <svg
                  className="ml-2 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <Hr className="hidden md:block" />
            <MainCta />
          </main>
        </MainTownHeader>
        <Breadcrumb pages={pages} />
        <div className="ml-2 md:py-16 bg-white overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
              <div className="relative py-12">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    Urgences serrurier : intervention immédiate ou programmée
                  </h3>
                  {/*
                <!-- <h2 className="inline">
                - Faites vous dépanner par les meilleurs serruriers de votre ville
                </h2> --> */}
                </div>
                <p className="mt-6 text-lg text-gray-600">
                  Vous rencontrez un souci avec votre porte ou votre clé s'est
                  coincée dans une serrure, et vous n'avez pas pu y remédier ?
                  Seule l'intervention d'un serrurier d'urgence qualifié de chez
                  Super Serrurier peut vous aider.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Nos serruriers Montreux sont à votre disposition pour des
                  interventions urgentes ou sur rendez-vous. Et ce, peu importe
                  la nature du problème que vous rencontrez et votre lieu de
                  résidence à Montreux.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Montreux s'étend sur une superficie de 225 km2 et recèle des
                  endroits qui ont construit sa réputation honorable.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Que vous soyez à Baugy, Caux, Chaulin, Chernex, Collonge,
                  Fontanivent, Glion, Le Châtelard, Mont-Fleuri,
                  Vuarennes...etc, rassurez-vous, Montreux est complètement
                  desservie.
                </p>
                <div className="mt-8 sm:flex">
                  <div className="block md:hidden rounded-md shadow">
                    <a
                      href="tel:0791304768"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                    >
                      Appelez-nous
                    </a>
                  </div>
                  <div className="hidden md:block mt-3 sm:mt-0 lg:ml-0">
                    <a
                      href="/contact"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white shadow-sm bg-secondarycolor hover:bg-secondarycolor-light"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 hidden lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <div className="bg-white p-8 shadow-lg rounded-lg z-10">
                  <FormDevis />
                </div>
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h3 className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                    <span className="text-primarycolor">
                      Serrurier Montreux : votre partenaire de confiance !
                    </span>
                  </h3>
                  <div className="mt-3 text-lg text-gray-600">
                    <p>
                      Super Serrurier Montreux se propose de vous apporter son
                      aide pour tous vos problèmes de serrurerie. En effet, nous
                      nous chargeons de vous envoyer nos meilleurs artisans
                      compétents et formés pour intervenir en cas d'urgence de
                      serrurerie à Montreux.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      Chacun de nos serruriers à Montreux partage en tous points
                      nos principes à savoir : honnêteté, savoir-faire et
                      engagement envers chaque client.
                    </p>
                  </div>
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg
                    className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    ></rect>
                  </svg>
                  <Image
                    className="relative mx-auto"
                    fluid={googleImage.childImageSharp.fluid}
                    fadeIn={false}
                    loading="eager"
                    alt="Meilleur serrurier de Genève"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    DEPANNAGE SERRURERIE DISPONIBLE 24h/24 et 7j/7
                  </h3>
                  {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                </div>
                <p className="mt-3 text-lg text-gray-600">
                  Vous ne pouvez pas accéder à votre domicile ou à votre local ?
                  Des situations comme celle-ci ne sont pas rares - inutile de
                  vous affoler ! Contactez-nous sans tarder, nous serons ravis
                  de vous secourir.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Chez Super Serrurier, nous mettons à votre disposition une
                  équipe de serruriers qualifiés. Ces derniers sont en mesure de
                  vous venir en aide dans les 20 minutes suivant votre coup de
                  fil.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Ces horaires peuvent arranger beaucoup d'entre vous si vous
                  rencontrez des difficultés en fin de semaine ou en fin de
                  soirée. De plus, nos serruriers d'urgence à Montreux se
                  tiennent à votre disposition également durant les jours fériés
                  pour vos urgences.
                </p>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <Image
                  className="relative mx-auto w-4/5"
                  fluid={genevaMap.childImageSharp.fluid}
                  fadeIn={false}
                  loading="eager"
                  alt="Déplacement gratuit et sans engagement"
                />
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>
          </div>
        </div>

        <Testimonials />

        <div className="ml-2 mt-4 md:py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-0">
              <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="relative mt-12 lg:mt-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="relative">
                    <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                      <h3 className="inline text-primarycolor">
                        Des interventions à des tarifs raisonnables
                      </h3>
                      {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                    </div>
                    <p className="mt-3 text-lg text-gray-600">
                      Les prix accessibles de nos interventions ont permis à
                      notre entreprise de se créer une place respectable parmi
                      nos concurrents.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      Nos serruriers professionnels s'engagent à vous proposer
                      un excellent rapport qualité/prix, des prestations à des
                      prix corrects et non excessifs.
                    </p>
                    <div className="mt-8 sm:flex">
                      <div className="block md:hidden rounded-md shadow">
                        <a
                          href="tel:0791304768"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                        >
                          Appelez-nous
                        </a>
                      </div>
                      <div className="hidden md:block mt-3 sm:mt-0 sm:ml-3 lg:ml-0">
                        <a
                          href="/contact"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondarycolor shadow-sm border-gray-200 bg-white hover:bg-gray-200"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="mt-10 -mx-4 relative lg:mt-0"
                    aria-hidden="true"
                  >
                    <Image
                      fluid={teamImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      style={{ borderRaduis: '7px' }}
                      className="relative mx-auto"
                      alt="Serrurier Éthique et responsable"
                    />
                  </div>
                </div>
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-28 lg:items-center mt-12 lg:mt-32">
                  <div className="lg:col-start-2">
                    <h2 className="text-2xl font-extrabold text-primarycolor tracking-tight sm:text-3xl">
                      Devis gratuit et une franchise absolue !
                    </h2>
                    <div className="mt-3 text-lg text-gray-600">
                      <p>
                        Les prix des services fournis par nos serruriers à
                        Montreux sont communiqués sous forme de fourchettes.
                      </p>
                      <p className="mt-3 text-lg text-gray-600">
                        Les urgences de serrurerie à Montreux sont assez
                        récurrentes, mais elles ne sont pas un prétexte pour
                        justifier les mauvaises pratiques. De ce fait, nous
                        pratiquons des tarifs transparents et qui ne sont soumis
                        à aucune augmentation inattendue.
                      </p>
                    </div>
                  </div>
                  <div className="-mx-4 relative lg:mt-0 lg:col-start-1 mt-12">
                    <svg
                      className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                      width="784"
                      height="244"
                      fill="none"
                      viewBox="0 0 784 404"
                      aria-hidden="true"
                    >
                      <defs>
                        <pattern
                          id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          ></rect>
                        </pattern>
                      </defs>
                      <rect
                        width="784"
                        height="404"
                        fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                      ></rect>
                    </svg>
                    <Image
                      fluid={deplacementGratuitImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      alt="Serrurier rapide et efficace"
                      className="relative mx-auto"
                    />
                  </div>
                </div>

                <div className="relative mt-12 lg:mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="relative">
                    <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                      <h3 className="inline text-primarycolor">
                        Une entreprise engagée sur le plan écologique
                      </h3>
                      {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                    </div>
                    <p className="mt-3 text-lg text-gray-600">
                      Il est vrai que depuis 2017, Super serrurier a su se
                      démarquer de ses concurrents par son engagement en faveur
                      de l'écologie.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      Pour preuve, pratiquement 30% de nos serruriers
                      montreusiens se rendent chez les clients en vélo
                      électrique. Il faut dire que cela les fait gagner en
                      rapidité en cas d'embouteillages.
                    </p>
                  </div>

                  <div
                    className="mt-10 -mx-4 relative lg:mt-0"
                    aria-hidden="true"
                  >
                    <Image
                      fluid={bikeImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      className="relative mx-auto"
                      style={{ borderRaduis: '7px' }}
                      alt="Entreprise éco-responsable"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Zones
          title="Nos zones d’interventions"
          text="Notre réseau serrurier Genève est disponibles 24/7 toute l'année, même les jours fériés, dans tout le canton de Geneve. Que vous soyez à Carouge, Meyrin, Satigny, Versoix ou à Thônex, vous trouverez toujours un super serrurier près de chez vous où que vous soyez."
        />
        <Prestations
          title="En quoi pouvons-nous vous être utiles ? Nos services"
          description="En tant qu'entreprise de serrurerie polyvalente, nous assurons plusieurs prestations de serrurerie en faveur des particuliers et des professionnels à Montreux."
        />
        <FaqSection content={GenevaFaq} />

        <BlogSection />
      </div>
    </Layout>
  );
}

const MainTownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('https://res.cloudinary.com/dxm0sdgpv/image/upload/v1642377976/super-serrurier/P58a_QWsXzXQKIVcf7ZJnLzQCF-SM1X5AW5vl6K4qdit4Ul6hKBq0SRQ8vVMEXAw5M4DPIcnZErp6yLJiX3gaJjv1H-Z4Qg4NxIRMoeVR3EBvbmfZYRy9YzJBYV-3yAHVUUC5Gn5_tbk05v.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 767px) {
    background-image: none;
  }
`;

const Hr = styled.hr`
    height: 1.5px;
    width: 15%;
    background: #fff;
    margin: auto;
    margin-top: 2rem;
}
`;
